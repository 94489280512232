import React from "react"

import { navigate } from 'gatsby-link'
import Layout from "../../components/layout"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout>
      <h1>Schedule demo</h1>
      <form
        name="demo"
        method="post"
        action="/schedule-demo/thank-you/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="demo" aria-label="demo"/>
        <p hidden>
          <label for="honeypot" id="honeypot-label">
            Don’t fill this out: <input aria-labelledby="honeypot-label" id="honeypot" name="bot-field" onChange={handleChange} />
          </label>
        </p>
				<label for="fullname" id="fullname-label">
					Name
					<input aria-labelledby="fullname-label" type="text" id="fullname" name="name" onChange={handleChange} />
				</label>
				<label for="email" id="email-label">
					Email
					<input aria-labelledby="email-label" type="email" id ="email" name="email" onChange={handleChange} />
				</label>
				<label for="org" id="org-label">
					Organization
					<input aria-labelledby="org-label" type="text" name="org" onChange={handleChange} />
				</label>
        <p>
          <button type="submit">Schedule demo</button>
        </p>
      </form>
    </Layout>
  )
}
